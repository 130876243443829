@font-face {
  font-family: 'Blair Medium';
  src: url('../fonts/BlairMdITCTTMediumFont.ttf');
}

@font-face {
  font-family: 'Arial Bold';
  src: url('../fonts/ARIALBD.TTF');
}

@font-face {
  font-family: 'Arial Regular';
  src: url('../fonts/ARIAL.TTF');
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
button,
th,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert,
.card-header,
.MuiTab-root {
  font-family: 'Arial Bold' !important;
  letter-spacing: 1px;
}

p,
a,
button,
td,
span,
label,
.form-control,
.form-control-file,
.form-control-range,
.textBox {
  font-family: 'Arial Regular' !important;
  letter-spacing: 1px;
}

.cr-sidebar__content span {
  font-family: 'Blair Medium' !important;
  color: #000;
}

.cr-header {
  margin-bottom: $spacer * 0.5;
  border-bottom: $border-width solid $border-color;

  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }

  // &__notification-popover {
  //   &.popover {
  //     width: 320px;
  //     max-width: 320px;
  //     max-height: 100vh;
  //     overflow-y: scroll;
  //     cursor: pointer;
  //   }
  // }
}

// .form-group label, .form-check-label,h6{
//   color: #212529;
// }
// .bg-white{
//   background: #202020  !important;
// }
.btn-outline-secondary {
  color: #000;
  border-color: transparent;
}

.btn-outline-secondary:hover {
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #000;
  background-color: transparent !important;
  border-color: transparent !important;
}

hr {
  border-top: 1px solid #000;
}

.text-secondary {
  color: rgb(69, 69, 69) !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0rem #d71920 !important;
}

.btn-primary,
.btn-primary:hover {
  background: #d71920 !important;
  margin-right: 1% !important;
}

.MuiTypography-body1 .MuiTypography-body1 .btn-primary:hover,
.MuiTypography-body1 .btn-primary {
  background: #d71920 linear-gradient(180deg, #d71920, #d71920) repeat-x !important;
  border-radius: 0.25rem;
}

.btn-primary {
  display: inline-block;
  margin-top: 5px;
  min-width: 160px;
  border-radius: 32px;
  border: 0px solid rgb(69, 69, 69);
}

// .navbar {
//   padding: 0;
// }

.navbar-brand {
  margin-right: 0;
}

// .Colbl label, h6{
//   color:#FFF;
// }
.MuiDialog-paperWidthSm {
  max-width: 950px !important;
}

.formwdth Input {
  width: 84%;
  float: right;
}

.MuiOutlinedInput-adornedEnd {
  border: 1px solid #fff;
  background-color: #fff;
}

.MuiOutlinedInput-adornedEnd input {
  color: #fff;
}

.MuiDialog-paperScrollPaper {
  box-shadow: 0px 0px 3px 1px #fff !important;
}

.react-confirm-alert-body {
  color: #fff !important;
  background: #d71920 !important;
}

.react-confirm-alert {
  box-shadow: 0px 0px 3px 1px #fff !important;
  border-radius: 8px !important;
}

Input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

#tabCol button::-moz-focus-inner {
  padding: 0 !important;
  border: none !important;
  outline: none !important;
}

// .card-body{
//   background-color:#4EA69D!important;
// }
.formwdthExpns {
  width: 45%;
}

.textBox {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  background-color: #e6e6e6;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 32px !important;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

// .react-datepicker-popper{
//   will-change: transform;
//   // position: fixed !important;
//   // top: 48% !important;
//   left: 48% !important;
//   transform: translate3d(3px, 38px, 0px) !important;
// }
.PadLeft0 {
  padding-left: 0%;
  padding-right: 20px;
}

.switch-handle {
  border: 1px solid darkgray;
}

.btn-sm,
.btn-group-sm > .btn {
  border-radius: 1.2rem;
}

#caladd .react-datepicker-popper {
  top: 64% !important;
}

#calend .react-datepicker-popper {
  top: 37% !important;
}

.btn-secondary {
  background: rgb(69, 69, 69)
    linear-gradient(180deg, rgb(69, 69, 69), rgb(69, 69, 69)) repeat-x;
  border-color: rgb(69, 69, 69);
}

.btn-secondary:hover {
  border-color: rgb(69, 69, 69);
}

.form-control {
  border-radius: 0.7rem;
  padding: 0.5rem 0.5rem;
  height: calc(2rem + 0.75rem + 2px);
  // font-size: 14px;
}

tr:first-child th:first-child {
  border-top-left-radius: 32px;
}

tr:first-child th:last-child {
  border-top-right-radius: 32px;
}

tr:last-child th:first-child {
  border-bottom-left-radius: 32px;
}

tr:last-child th:last-child {
  border-bottom-right-radius: 32px;
}

tr td:first-child {
  border-top-left-radius: 32px;
}

tr td:last-child {
  border-top-right-radius: 10px;
}

tr td:first-child {
  border-bottom-left-radius: 10px;
}

tr td:last-child {
  border-bottom-right-radius: 15px;
}

th {
  background-color: rgb(69, 69, 69);
  color: #fff;
  font-weight: 600 !important;
}

.card {
  background-color: #f8f9fa;
}

td {
  background-color: #232224;
  color: #fff;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 2px;
}

.react-datepicker-wrapper {
  width: 100%;
}

input[type='checkbox'] {
  height: calc(0rem + 0.75rem + 7px);
}

#expensetable .table thead th,
#expensetable .table td {
  border-top: 0px solid #1b1b1b;
  border-bottom: 0px solid #1b1b1b;
  border-left: 0px solid #1b1b1b;
  font-size: 14px;
}

.table th {
  border-right: 1px solid #dee2e6 !important;
  border-left: 1px solid #dee2e6 !important;
  text-align: left !important;
}

.signout {
  background: #fff !important;
}

.signout svg {
  color: #000 !important;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.table {
  tr:last-child th:first-child {
    border-bottom-left-radius: 0px;
  }

  tr:last-child th:last-child {
    border-bottom-right-radius: 0px;
  }

  tr td:first-child {
    border-top-left-radius: 0px;
  }

  tr td:last-child {
    border-top-right-radius: 0px;
  }

  tr td:first-child {
    border-bottom-left-radius: 0px;
  }

  tr td:last-child {
    border-bottom-right-radius: 0px;
  }

  // tr:first-child th:first-child {
  //   border-top-left-radius: 7px;
  // }

  // tr:first-child th:last-child {
  //   border-top-right-radius: 7px;
  // }

  tr:last-child td:first-child {
    border-bottom-left-radius: 32px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 32px;
  }

  // box-shadow: 0px 2px 5px 0px rgba(45, 44, 44, 0.2);
  border-radius: 15px;
  // box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
  // text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

#expensetable .table thead th {
  border-left: 0px solid transparent;
}

.backEmpl {
  background-color: #fff;
  padding-top: 1% !important;
  display: inline-table;
  border-radius: 10px;
  // text-align: center;
  margin-left: 6%;
  margin-top: 2%;
  box-shadow: 0px 2px 5px 0px rgba(45, 44, 44, 0.2);
}

.MuiTabs-fixed {
  overflow: auto !important;
}

// ::-webkit-scrollbar {
//   width: 5px;
// }
.PadText {
  // text-align: left;
  padding-left: 20%;
}

.backEmpl .col-md-6,
.backEmpl .col-md-12,
.backEmpl .col-md-7 {
  padding-top: 2%;
  margin-bottom: 0rem !important;
  padding-bottom: 3%;
}

.cr-sidebar {
  width: 50px !important;
  left: 0 !important;
}

.cr-sidebar--open {
  left: 0 !important;
  width: 320px !important;
  border-radius: 32px !important;
}

.cr-sidebar + .cr-content {
  margin: 0 0 0 50px !important;
}

.cr-sidebar--open,
.cr-sidebar {
  transition: left 0.3s ease-in !important;
}

.cr-sidebar + .cr-content {
  transition: margin 0.4s ease-in !important;
}

.cr-sidebar--open + .cr-content {
  margin: 0 0 0 320px !important;
  transition: margin 0.4s ease-in !important;
}

.cr-sidebar .nav .nav-link {
  font-size: 11px;
  letter-spacing: 0.075em;
  text-transform: capitalize !important;
}

.cr-sidebar__nav-item-icon {
  width: 1.9rem !important;
  height: 2rem !important;
  padding-right: 0.8rem !important;
  margin-right: 12px !important;
  color: black;
}

#navItem-dashboard-2 {
  .cr-sidebar__nav-item-icon {
    width: 1.9rem !important;
    height: 2rem !important;
    padding-right: 0.8rem !important;
  }

  .cr-sidebar .nav .nav-link {
    padding: 0.5rem 1.3rem !important;
  }
}

#navItem-dashboard-0 {
  .cr-sidebar__nav-item-icon {
    width: 1.9rem !important;
    height: 2rem !important;
    padding-right: 0.8rem !important;
  }

  .cr-sidebar .nav .nav-link {
    padding: 0.5rem 1.1rem !important;
  }
}

.cr-sidebar--open .cr-sidebar__nav-item-icon,
.cr-sidebar--open #navItem-dashboard-7 .cr-sidebar__nav-item-icon {
  width: 1.9rem !important;
  height: 2rem !important;
  margin-right: 0px !important;
}

.cr-sidebar .nav .nav-item {
  margin: 10px 30px;
}

.imgside {
  display: none;
}

.cr-sidebar--open .imgside {
  display: block;
}

.duaringaText {
  font-size: 0px !important;
}

.cr-sidebar--open .duaringaText {
  font-size: 20px !important;
}

#a11y-tab-1::after {
  content: '\a';
  white-space: pre;
}

.folderImg {
  background-color: #fff;
  text-align: center;
  padding: 4% 0;
  border-radius: 15px;
}

.folderImg img {
  margin-left: auto;
}

.folderImg a {
  text-decoration: none;
  color: #212529;
}

.searchdiv {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-right: 0;
}

.searchicon {
  // padding-top: 1%;
  width: 4%;
  background: rgb(69, 69, 69);
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 10px;
  padding-top: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

// .searchicon i,.form-group label{
//   color: #fff;
// }
.form-group label {
  font-size: 14px;
}

.col-sm-8 {
  width: 85%;
}

.boxShadow td,
.boxShadow th {
  box-shadow: 0px 0px 1px 0px #fff;
}

.form-control:focus {
  color: #000;
  background-color: #fff;
  // border-color: #ced4da;
  border: solid 2px black;
  outline: 0;
  box-shadow: unset;
  transition: 0.2s;
}

// #twotabs #tabCol .MuiAppBar-root, #schedule #tabCol .MuiAppBar-root{
//   width: 28% !important;
// }
// #tabColpen #tabCol .MuiAppBar-root{
//   width:29% !important;
// }
.detailrow {
  padding-top: 1%;
  border: 1px solid rgb(69, 69, 69);
  border-radius: 20px;
  box-shadow: 0px 0px 1px 2px rgb(69, 69, 69);
  margin: 4%;
}

.scoreimg img {
  width: 95%;
  padding: 2%;
  position: absolute;
  border-radius: 18px;
}

// #score1{
//   margin-left:2%;
// }
.subscore {
  padding-left: 6%;
}

#familyTable th,
#familyTable1 th,
#familyTable2 th {
  font-weight: bolder;
}

.penset h4 {
  text-transform: uppercase;
  font-size: 15px;
}

.btn-block {
  width: 100% !important;
  border-radius: 5px !important;
  transition: 0.3s;
}

.btn-block:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.remember-me:hover {
  cursor: pointer;
  border-bottom: solid black 1px;
}

// #tabColpen #tabCol .MuiAppBar-root {
//   width: 24% !important;
// }
#twotabs #tabCol .MuiAppBar-root,
#schedule #tabCol .MuiAppBar-root {
  width: 324px !important;
}

#schedule #tabCol1 .MuiAppBar-root {
  width: 482px !important;
}

// #schedule #tabCol1 .MuiAppBar-root {
//   width: 44% !important;
// }
.cr-sidebar path {
  stroke: #fff !important;
}

#disc {
  margin-left: 15px;
  margin-top: 10%;
}

.discard {
  padding-left: 14%;
  margin-top: 10%;
}

.bg-gradient-theme-left,
.backColSt {
  background: #d71920 linear-gradient(180deg, #d71920, #d71920) repeat-x !important;
}

.backColSt {
  border-radius: 2.2rem !important;
}

.react-confirm-alert-body > h1 {
  font-size: 22px !important;
  color: white;
}

// @media only screen and (max-width: 1160px){
//   #tabColpen #tabCol .MuiAppBar-root {
//     width: 29% !important;
// }
// #twotabs #tabCol .MuiAppBar-root, #schedule #tabCol .MuiAppBar-root {
//   width: 28% !important;
// }
// #schedule #tabCol1 .MuiAppBar-root {
//   width: 44% !important;
// }
// }
// @media only screen and (max-width: 1440px){
//   #tabColpen #tabCol .MuiAppBar-root {
//     width: 24% !important;
// }
// #twotabs #tabCol .MuiAppBar-root, #schedule #tabCol .MuiAppBar-root {
//   width: 23% !important;
// }
// #schedule #tabCol1 .MuiAppBar-root {
//   width: 36% !important;
// }
// }

@media only screen and (max-width: 576px) {
  .searchicon {
    padding-top: 4%;
    width: 10%;
  }

  .table thead th,
  .table td {
    border-top: 1px solid transparent !important;
    border-bottom: 1px solid #1b1b1b !important;
    font-size: 14px;
  }

  .backEmpl {
    margin-top: 10%;
  }

  .backEmpl .col-md-6 {
    text-align: center;
  }

  .PadText {
    padding-left: 0;
  }
}

@media only screen and (max-width: 770px) {
  .PadText {
    padding-left: 0;
  }

  #tabCol .MuiAppBar-root {
    width: 93% !important;
  }

  #twotabs #tabCol .MuiAppBar-root {
    width: 48% !important;
  }

  .cr-content {
    width: 90% !important;
  }

  .detailrow {
    margin-top: 20%;
  }

  .respPad {
    padding: 0 !important;
    padding-top: 4% !important;
  }

  #schedule #tabCol .MuiAppBar-root {
    width: 42% !important;
  }

  .backEmpl {
    margin-left: 2%;
    margin-right: 2%;
  }

  .table-responsive {
    margin-top: 8%;
  }
}

@media only screen and (max-width: 321px) {
  .MuiTab-root {
    min-width: 63px !important;
  }
}

@media only screen and (min-width: 2500px) {
  #twotabs #tabCol .MuiAppBar-root,
  #schedule #tabCol .MuiAppBar-root {
    width: 15% !important;
  }

  #tabCol .MuiAppBar-root {
    width: 30%;
  }

  .discard {
    margin-top: 6%;
  }

  #disc {
    margin-top: 6%;
  }
}

.penset {
  margin: auto;
}

.penset tr td {
  padding: 15px;
  border: 2px solid #000;
  background: #fff !important;
}

.penset tr td:first-child {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 32px;
}

.penset tr td:last-child,
.penset tr:last-child td:last-child,
.penset tr:last-child td:first-child {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.treatName .MuiAutocomplete-option,
.treatName .MuiInputBase-root,
.MuiAutocomplete-option {
  background: #fff !important;
  color: #000;
  // border:1px solid #fff;
}

.MuiIconButton-root {
  color: #000 !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
.MuiAutocomplete-hasPopupIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  border-radius: 0.7rem !important;
  padding: 0rem 1rem !important;
  height: calc(2rem + 0.75rem + 2px) !important;
}

.MuiTypography-body1 {
  font-size: 1rem !important;
}

// .MuiBox-root-11, .MuiBox-root-10,.MuiBox-root-9, .MuiBox-root-8, .MuiBox-root-7,.MuiBox-root-6,.MuiBox-root-12,.MuiBox-root-13 ,.MuiBox-root-14 {
//   padding: 15px !important;
// }
.quickfrmae {
  height: 100vh !important;
}

// .bg-light {
//   background-color: #222223 !important;
// }

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding: 0px !important;
}

.plusSign tr td:last-child {
  display: none;
}

.plusSign tr:last-child td:last-child {
  display: block;
}

.form-control {
  color: #000;
  background-color: #fff;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.autoCompS .MuiInputBase-root {
  color: #000 !important;
  background: #fff !important;

  font-size: 14px !important;

  /* border-radius: 3.5rem; */
  border-top: 1px solid #fff;
  position: unset !important;
}

.autoCompS .MuiFormControl-root {
  border: 1px solid #fff;
  border-radius: 0.7em !important;
}

.autoComp .MuiInputBase-root {
  color: #000 !important;
  background: #fff !important;

  font-size: 14px !important;

  /* border-radius: 3.5rem; */
  border-top: 1px solid #fff;
  position: unset !important;
}

.autoComp .MuiFormControl-root {
  border: 1px solid #fff;
  border-radius: 0.7em !important;
}

.autoComp input {
  width: 100%;
  padding: 0rem 1rem;
  border-radius: 10px;
  color: #000;
  background-color: #fff !important;
  border: 1px solid #fff;
  height: calc(2rem + 0.75rem + 2px);
}

.closeDate {
  position: absolute;
  right: 25px;
  top: 13px;
  background: transparent;
  color: #fff;
  width: 20px;
}

.dialogTxt div {
  box-shadow: 2px 2px 4px -2px #000;
  padding: 1%;
}

.penset {
  width: 97% !important;
}

.penset tbody td {
  padding: 0 !important;
  background-color: #454545 !important;
  border: 1px solid #454545;
}

.penset tbody td button {
  background-color: #454545;
  width: 100%;
  color: #fff;
  padding: 5%;
  // border:1px solid #454545;
}

.blackButton {
  border: 1px solid #f85032;
}

.whiteButton {
  border: 1px solid #454545;
}

.colrChnge {
  color: gray !important;
}

.backButton {
  background: #f85032 linear-gradient(180deg, #d71920, #f85032) repeat-x !important;
}

// .parentDisable{
//   position: fixed;
//   top: 0;
//   left: 0;
//   background: #666;
//   opacity: 0.8;
//   z-index: 998;
//   height: 100%;
//   width: 100%;
// }
.overlay-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: #666666;
  opacity: 0.8;
  z-index: 99999 !important;
  width: 100%;
  height: 100vh !important;
}

.css-i6dzlt {
  border-color: #fff !important;
  margin-top: 20% !important;
  // left:40% !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #d71920;
}

//---------Task Management------------//
.FileCss {
  width: 100%;
  border: 2px solid #ced4da !important;
  padding: 8px;
  text-align: center;
  border-radius: 0.7rem !important;
  cursor: pointer;
}

.callCycle {
  width: 100%;
  border-radius: 0.7rem;
  padding-left: 6%;
}

input[type='checkbox'] {
  height: unset !important;
}

.clientCheck[type='checkbox'] {
  height: calc(0rem + 0.75rem + 25px) !important;
}

.clientCheckSub[type='checkbox'] {
  height: calc(0rem + 0.75rem + 83px) !important;
}

.taskBorder {
  // border-top: 1px dotted #000;
  padding-top: 15px;
}

p {
  font-size: 14px !important;
}

.TableSet {
  td {
    background-color: transparent;
    color: #232224;
  }
}

.antdTable {
  border: solid #e6e6e6 2px;
  border-radius: 32px;
  padding: 20px;
  overflow-x: scroll;

  margin-top: 15px;

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table {
    border-top: transparent;
  }

  td {
    background-color: transparent;
    color: #232224;
  }

  tr {
    border-radius: 32px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 32px !important;
    border: transparent;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 32px;
  }

  th:first-child {
    border-top-left-radius: 32px;
  }

  tr:last-child th:last-child {
    border-bottom-right-radius: 32px !important;
  }

  tr:last-child th:last-child th:first-child {
    border-bottom-right-radius: 32px !important;
  }

  tr td:last-child {
    border-bottom-right-radius: 32px !important;
  }

  tr td:last-child {
    border-top-right-radius: 32px !important;
  }

  tr td:first-child {
    border-top-left-radius: 32px !important;
  }

  tr td:first-child {
    border-bottom-left-radius: 32px !important;
  }

  tr:last-child th:first-child {
    border-bottom-left-radius: 32px !important;
  }
}

.modalRow {
  margin-top: 15px;
}

// .modalRow .col{
//   display: flex;
//   justify-content: space-around;
// }
.btn-secondary:hover {
  color: #fff;
  background-color: #454545;
  background-image: none;
  border-color: #454545;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #454545;
  background-image: none;
  border-color: #454545;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: unset;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: unset !important;
}

// .multiselect button {
//   border-radius: 0.7rem !important;
//   // border-radius: 32px;
//   padding: 0rem 0.5rem !important;
//   height: calc(2rem + 0.75rem + 2px) !important;
//   font-size: 14px !important;
//   font-family: 'Arial Regular' !important;
//   letter-spacing: 1px !important;
//   width: 100% !important;
//   border: 1px solid #ced4da !important;
//   box-shadow: none !important;
//   color: #000 !important;
// }

.rmsc .dropdown-container {
  border-radius: 32px !important;
  background-color: #e6e6e6 !important;
  height: 46px !important;
}

.ant-btn {
  margin-left: 7px;
  margin-bottom: 6px;
}

.btnGap {
  gap: 7px !important;
  margin-left: 6px;
  margin-bottom: 10px;
  margin-top: 6px;
  margin-right: 6px;
}

.dialogHeader {
  background-color: #d71920 !important;

  h2 {
    color: #fff !important;
    text-align: center !important;
  }
}

.ant-progress-bg {
  background-color: red !important;
}

.tox-statusbar__branding {
  display: none !important;
}

.scroll_vertical {
  display: grid;
  flex-direction: row;
  height: 323px;
  // height: 35vh;
}

#addScroll {
  flex: 1;
  overflow-y: scroll;
}

// UI/UX Remodel Additions
// Add .for page
.admin-container {
  width: 700px;
  border: solid #e6e6e6 2px;
  border-radius: 32px;
  padding: 0px 40px 0px 40px;
  overflow: scroll;
}

.admin-input {
  background-color: #e6e6e6;
  border-radius: 32px;
}

.admin-input:focus {
  background-color: #e6e6e6;
}

.greyBanner {
  margin: 20px 0px 0px 0px;
  max-width: 400px;
  float: right;
}

//Add Rep
.rep-container-1 {
  width: 50%;
  max-height: 620px;
  border: solid #e6e6e6 2px;
  border-radius: 32px;
  padding: 20px 5px 20px 20px;
  // overflow-y: scroll;

  p {
    margin-bottom: 0;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.rep-container-21 {
  // display: inline-block;
  position: absolute;
  float: left;
  right: 42px;
  // right: 39px;
  // margin-right: 250px;
  width: 100%;
  // height: 840px;
  // max-width: 1000px;
  max-height: 620px;
  border-top: solid #e6e6e6 2px;
  border-right: solid #e6e6e6 2px;
  border-bottom: solid #e6e6e6 2px;
  border-radius: 0px 32px 32px 0px;
  padding: 20px 5px 14px 40px;
  // padding: 40px 0 25px 40px;
  overflow-y: scroll;
  p {
    margin-bottom: 0;
  }
}

.rep-container-22 {
  position: absolute;
  float: left;
  right: 42px;
  width: 100%;
  // height: 592px;
  border-top: solid #e6e6e6 2px;
  border-right: solid #e6e6e6 2px;
  border-bottom: solid #e6e6e6 2px;
  border-radius: 0px 32px 32px 0px;
  padding: 20px 40px 36px 40px;
  overflow-y: scroll;
}

.rep-container-23 {
  // display: inline-block;
  position: absolute;
  float: left;
  right: 39px;
  // margin-right: 250px;
  width: 100%;
  // height: 840px;
  // max-width: 1000px;
  max-height: 620px;
  border-top: solid #e6e6e6 2px;
  border-right: solid #e6e6e6 2px;
  border-bottom: solid #e6e6e6 2px;
  border-radius: 0px 32px 32px 0px;
  padding: 36px 0px 2px 40px;
  overflow-y: scroll;
  top: 25px;
}

.rep-container-2 {
  // display: inline-block;
  position: absolute;
  float: left;
  right: 42px;
  // margin-right: 250px;
  width: 100%;
  // height: 840px;
  // max-width: 1000px;
  max-height: 620px;
  border-top: solid #e6e6e6 2px;
  border-right: solid #e6e6e6 2px;
  border-bottom: solid #e6e6e6 2px;
  border-radius: 0px 32px 32px 0px;
  padding: 35px 0px 2px 40px;
  overflow-y: scroll;
}

.admin-row {
  min-height: 76px;
}

.title-coloumn {
  margin-left: 51.5%;
}

// Supplier Page
.supplier-main-container {
  width: 100%;
  height: 100%;
  // border: black solid 3px;
}

.left-supplier-container {
  margin-left: 20px;
}

.right-supplier-container {
  margin-right: 20px;
}

.upload-logo {
  text-align: center;
  padding-top: 30px;
  width: 100%;
  height: 100px;
  border-radius: 32px;
  border: solid 2px #e6e6e6;
}

.supplier-details {
  width: 100%;
  max-height: 100vh;
  border: solid 2px #e6e6e6;
  border-radius: 32px;
  padding: 20px 10px 20px 40px;
  overflow-y: scroll;
}

.supplier-call-cycle {
  width: 100%;
  max-height: 360px;
  border: solid 2px #e6e6e6;
  border-radius: 32px;
  padding: 20px 40px 20px 40px;
}

.supplier-contact {
  width: 100%;
  max-height: 260px;
  border: solid 2px #e6e6e6;
  border-radius: 32px;
  margin-top: 5px;
  padding: 20px 40px 0px 40px;
}

.document-upload {
  width: 100%;
  max-height: 180px;
  border: solid 2px #e6e6e6;
  border-radius: 32px;
  margin-top: 5px;
  padding: 20px 40px 20px 40px;
}

// Call cycle page
.ant-table-wrapper {
  background: transparent;
}

.ant-table-cell th {
  background: yellow;
}

ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  float: left;
}

div .phnclass {
  background-color: #e6e6e6 !important;
  border-radius: 32px !important;
  font-size: 0.7rem !important;

  input {
    background-color: #e6e6e6 !important;
  }
}

select {
  background-color: grey;
}

.PhoneInput {
  padding: 0rem 0.5rem;
  height: calc(2rem + 0.75rem + 2px);
  font-size: 0.7rem !important;
  letter-spacing: 1px !important;
  border: 1px solid #ced4da;
}

.PhoneInputInput {
  background-color: #e6e6e6;
  border-radius: 32px;
  letter-spacing: 1px !important;

  border: 0px solid transparent !important;
}

// Labels
label {
  font-size: 0.7rem !important;
}

MultiSelect {
  border-radius: 32px;
}

.upload-logo {
  transition: 0.3s;
}

.upload-label:hover {
  cursor: pointer;
  transition: 0.3s;
  border-color: #eeeeee !important;
  border-radius: 40px !important;
}

// Requested Adhoc View Individual Task
.individual-adhoc-task-container {
  width: 50%;
  border: solid #e6e6e6 2px;
  border-radius: 32px;
  padding: 20px;
  overflow: scroll;
}

.card-header {
  margin-top: 1rem;
}

.uploadExcel label {
  font-size: 16px !important;
}

.hidden {
  display: none;
  visibility: hidden;
}

.HiOutlineXCircle:hover {
  transition: 0.3s;
  opacity: 0.7;
  cursor: pointer;
}

.MuiDropzoneArea-root {
  min-height: 100px !important;
  border-radius: 32px !important;
  border: solid 2px #e6e6e6 !important;
}

.react-time-picker__wrapper {
  border: 0px solid transparent !important;
}

/* Loading Bubble Zero */
.lds-ellipsis {
  position: relative;
  width: 80px;
  height: 40px;
  float: right;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #d71920;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

@media screen {
  .print-screen {
    overflow: hidden;
    // display: none;
    height: 0;
  }
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background: #454545 linear-gradient(180deg, #454545, #454545) repeat-x !important;
  border-color: #454545 !important;
}

td {
  text-transform: capitalize;
}

.form-control {
  font-size: 0.7rem !important;
  letter-spacing: 1px !important;
}

.rmsc .dropdown-container:focus-within {
  box-shadow: var(--rmsc-main) 0 0 0 0px !important;
  border-color: transparent !important;
}

.form-control:focus {
  border: solid 1px lightgray !important;
}

.css-16w3v0c-control,
.css-16w3v0c-control:hover,
.css-16w3v0c-control:active {
  border: 1px solid #ced4da !important;
}

.textBox {
  font-size: 0.7rem;
}

select:invalid {
  color: gray !important;
}
.emp select:invalid {
  color: gray !important;
}
.centerTd tr td:last-child {
  text-align: center !important;
}
.task-detail-desc p {
  font-size: 0.7rem !important;
}
.voidBtn {
  background: #d71920 !important;
  border-color: #d71920 !important;
}
.voidBtn.disabled,
.voidBtn:disabled {
  background: #d71920 linear-gradient(180deg, #d71920, #d71920) repeat-x !important;
  border-color: #d71920 !important;
}
.cr-content .container-fluid {
  overflow: hidden !important;
}
// warning message css
.ant-message .anticon {
  top: -3px !important;
}
